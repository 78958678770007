import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { ApmModule, ApmService } from "@elastic/apm-rum-angular";
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from "@azure/msal-angular";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, protectedMulesoftResources } from "./auth-config";
import { EbrSetupService } from "business-review/services/ebr-setup.service";
import { environment } from "../environments/environment";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SharedModule } from "./shared/shared.module";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { GlobalErrorHandler } from "./core/errorhandlers/apm-error-handler";

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ApmModule,
    MsalModule.forRoot(
      new PublicClientApplication(msalConfig),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: [environment.NET_READ_SCOPE],
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [
            `${environment.NET_API_HOST}/**`,
            [environment.NET_READ_SCOPE, environment.NET_WRITE_SCOPE],
          ],
          [`${environment.PUBLIC_API_HOST}/**`, [environment.API_SCOPE]],
        ]),
      }
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
  ],
  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    ApmService,
    provideAnimationsAsync(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    EbrSetupService,
    {
      provide : ErrorHandler,
      useClass : GlobalErrorHandler
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  msalUserInfo : UserObject;
  constructor(
    service: ApmService,
    private msal : MsalService
  ) {
    // Agent API is exposed through this apm instance
    const apm = service.init({
      serviceName: environment.APM_SERVICE_NAME,
      serverUrl: environment.APM_SERVER_URL,
      environment: environment.ENV_NAME
    });
    this.msalUserInfo = {
      id: this.msal.instance.getAllAccounts()[0]?.localAccountId,
      email: this.msal.instance.getAllAccounts()[0]?.localAccountId,
      username: this.msal.instance.getAllAccounts()[0]?.name
    }
    apm.setUserContext(this.msalUserInfo);
  }
}
