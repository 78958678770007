import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, catchError, map, throwError } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { CustomerApi, CustomerLevel1Info, ICustomerInfo, ServiceInsightResponse } from "interfaces/customer.interface";
import { HierarchyLevelRequest, EcolabHierarchyLevelRequest } from "interfaces/customer-level.interface";
import { IL1DetailsRequest, ISavedOperatingType } from "interfaces/IDetailsL1";
import { API_CONSTANTS } from "src/app/core/constants/end-point-constants";
import { environment } from "src/environments/environment";
import { Catogory } from "../../interfaces/value-active-metrics.interface";
import { IOperatingTypePayload, IOperatingTypeResponse, ISubOperatingTypeResponse } from "../../interfaces/operating-types.interface";
import { HOME_ICON, IMPERIAL, USD } from "src/app/core/constants/app.constants";
@Injectable({ providedIn: "root" })
export class CustomerService {
  customerListApi: string = `${environment.API_HOST}${API_CONSTANTS.getCustomers}`;
  isOverlayVisible: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  tileName: Subject<string> = new Subject<string>();
  currencySymbol: Subject<{ currencyCode: string; currencySymbol: string }> =
    new Subject<{ currencyCode: string; currencySymbol: string }>();
  public customerInfo: BehaviorSubject<ICustomerInfo> = new BehaviorSubject(null);
  public selectedOperatingType: BehaviorSubject<ISavedOperatingType> = new BehaviorSubject(null);
  public validOptType: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public navItems  = [{navItemName:'Home',navSrc:HOME_ICON}]

  constructor(private http: HttpClient) {}
  private userPreferencesSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public userPreferences$ = this.userPreferencesSubject.asObservable();
  public errorOccured: BehaviorSubject<boolean> = new BehaviorSubject(false);

  getCatogoryList(): Observable<Catogory> {
    return this.http
      .get<Catogory>(
        `${environment.NET_API_HOST}/ebr/valueactivitymetrics/categories`
      )
      .pipe(catchError(this.errorHandler));
  }
  // Get customer details
  getCustomerList(): Observable<CustomerApi> {
    return this.http
      .get<CustomerApi>(this.customerListApi)
      .pipe(catchError(this.errorHandler));
  }

  // Fetch the L1 Tile details
  getL1TileDetails(
    customerID: string,
    payload: IL1DetailsRequest
  ): Observable<CustomerLevel1Info> {
    return this.http
      .post<CustomerLevel1Info>(
        `${this.customerListApi}/${customerID}/level1`,
        payload
      )
      .pipe(catchError(this.errorHandler));
  }

  // Fetch the L2 Tile details
  getL2TileDetails(
    customerID: string,
    payload: IL1DetailsRequest
  ): Observable<CustomerLevel1Info> {
    return this.http
      .post<CustomerLevel1Info>(
        `${this.customerListApi}/${customerID}/level2`,
        payload
      )
      .pipe(catchError(this.errorHandler));
  }

  // Get spend data
  getSpendData(
    customerID: string,
    payload: IL1DetailsRequest
  ): Observable<CustomerLevel1Info> {
    return this.http
      .post<CustomerLevel1Info>(
        `${this.customerListApi}/${customerID}/spend`,
        payload
      )
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    this.errorOccured.next(true);
    console.error(error.message);
    return throwError(error.message || "Server Error");
  }

  // Get hierarchy level
  getHierarchyLevel(
    customerId: string,
    hierarchyLevelData: HierarchyLevelRequest
  ): Observable<any> {
    return this.http
      .post<any>(
        `${this.customerListApi}/${customerId}/customer-hierarchy-levels`,
        hierarchyLevelData
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMessage =
            error.error instanceof ErrorEvent
              ? `An error occurred: ${error.error.message}`
              : `Server returned code: ${error.status}, error message is: ${error.message}`;
          console.error(errorMessage);
          return throwError(() => new Error(errorMessage));
        })
      );
  }

  getEcoHierarchyLevel(
    customerId: string,
    hierarchyLevelData: EcolabHierarchyLevelRequest
  ): Observable<any> {
    return this.http
      .post<any>(
        `${this.customerListApi}/${customerId}/ecolab-hierarchy-levels`,
        hierarchyLevelData
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMessage =
            error.error instanceof ErrorEvent
              ? `An error occurred: ${error.error.message}`
              : `Server returned code: ${error.status}, error message is: ${error.message}`;
          console.error(errorMessage);
          return throwError(() => new Error(errorMessage));
        })
      );
  }

  // Get operating type list
  operatingTypeList(customerId: string): Observable<any> {
    return this.http
      .get<any>(this.customerListApi + "/" + customerId + "/operating-type")
      .pipe(catchError(this.errorHandler));
  }

  getDivisionFilters(customerId: string): Observable<any> {
    return this.http
      .get<any>(this.customerListApi + "/" + customerId + "/divisions")
      .pipe(catchError(this.errorHandler));
  }

  getPreferences(emailId: string): Observable<any> {
    const url = environment.NET_API_HOST + "/globalpreferences/userpreferences";
    return this.http.get<any>(url, {
      params: {
        EmailId: emailId,
      },
    }).pipe(catchError(this.errorHandler));;
  }

  getUserPreferencesObservable(emailId): Observable<any> {
    this.userPreferences$.subscribe((preference) => {
      if (!preference && emailId) {
        this.setRetrievedUserPreferences(emailId);
      }
    });
    return this.userPreferencesSubject.asObservable();
  }

  setRetrievedUserPreferences(emailId): void{
    this.getPreferences(emailId).subscribe((pref)=>{
      if(pref?.data?.userPreferences){
        this.userPreferencesSubject.next(pref?.data?.userPreferences);
      }
      else{
        let userPreferences = {currency: USD, uom: IMPERIAL}
        this.userPreferencesSubject.next(userPreferences);
      }
      
      sessionStorage.setItem('currency', pref?.data?.userPreferences?.currency ?? USD);
      sessionStorage.setItem('uom', pref?.data?.userPreferences?.uom ?? IMPERIAL);
    })
  }

  toggleOverlayVisibility(val: boolean) {
    this.isOverlayVisible.next(val);
  }
  getTileDetails(val: string) {
    this.tileName.next(val);
  }
  getServiceInsights(
    customerID: string,
    payload: IL1DetailsRequest
  ): Observable<ServiceInsightResponse> {
    return this.http
      .post<ServiceInsightResponse>(
        `${this.customerListApi}/${customerID}/services`,
        payload
      )
      .pipe(catchError(this.errorHandler));
  }

  private getDetails<T>(endPoint: string, customerID: string): Observable<T> {
    return this.http
      .get<T>(
        `${environment.API_HOST}/users/customers/${customerID}/${endPoint}`
      )
      .pipe(catchError(this.errorHandler));
  }

  fetchOperatingTypeList(customerID: string) {
    return this.getDetails<IOperatingTypeResponse>(
      "operating-type",
      customerID
    ).pipe(catchError(this.errorHandler));
  }

  fetchSubOperatingTypeList(customerID: String, opList: IOperatingTypePayload) {
    return this.http
      .post<ISubOperatingTypeResponse>(
        `${environment.API_HOST}users/customers/${customerID}/operating-type/sub-type`,
        opList
      )
      .pipe(catchError(this.errorHandler));
  }

  setCurrencySymbol(val: { currencyCode: string; currencySymbol: string }) {
    this.currencySymbol.next(val);
  }

  toHomepage() {
    window.location.href = environment.DI_HOME;
  }
}
 

